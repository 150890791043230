import { h } from "preact";
import { useState } from "preact/hooks";
import Cookies from "js-cookie";
import { getButtonProps } from "@pluralsight/headless-styles";
import Checkbox from "./checkbox";
import { CodeIcon, BriefcaseIcon, WindowsIcon } from "@pluralsight/icons";

const App = () => {
  const [dontShow, setDontShow] = useState(false);

  const isStaging = (typeof window !== "undefined") && /staging/.test(document.location.hostname)
  const learnUrl = isStaging
    ? "https://staging-learn.acloud.guru"
    : "https://learn.acloud.guru";
  const appUrl = isStaging
    ? "https://app-stage.pluralsight.com/id/signin/sso?Alias=acg-test"
    : "https://app.pluralsight.com/id/signin/sso?Alias=Acg-oidc";

  const handleBackClick = () => {
    document.location.href = learnUrl;
  };
  
  const handleForwardClick = () => {
    if (dontShow) {
      Cookies.set("dontshowoutbound", "true", {
        expires: 365,
        domain: ".acloud.guru",
      });
    }
    document.location.href = appUrl;
  };

  const handleCheckboxClick = (e) => {
    setDontShow(e.target.checked);
  };

  return (
    <div id="app">
      <div>
        <img
          alt=""
          src="/assets/acg-to-skills.png"
          srcset="/assets/acg-to-skills@2x.png 2x"
          width="214"
          height="64"
        />
        <h1>You're headed to Pluralsight Skills</h1>
        <p>
          Pluralsight is now the parent company of A Cloud Guru, so you have
          access to a learning plan there!
        </p>
        <div id="explainer-panel">
          <div>
            <ul>
              <li>
                {/* Icon not available in Pando */}
                <span class="iconWrapper">
                  <img
                    src="/assets/cap-machine-intelligence.svg"
                    width="20"
                    height="20"
                    alt=""
                  />
                </span>
                Data & Machine Learning
              </li>
              <li>
                <CodeIcon /> Software Development
              </li>
              <li>
                <BriefcaseIcon />
                Business
              </li>
              <li>
                {/* Icon not available in Pando */}
                <span class="iconWrapper">
                  <img
                    src="/assets/cap-security.svg"
                    width="22"
                    height="22"
                    alt=""
                  />
                </span>
                Security
              </li>
              <li>
                <WindowsIcon />
                IT Ops
              </li>
            </ul>
            <img
              id="skill-iq-graphic"
              alt=""
              src="/assets/skill-iq-graphic.png"
              srcset="/assets/skill-iq-graphic@2x.png 2x"
              width="225"
              height="172"
            />
          </div>
        </div>

        <div id="button-row">
          <button
            {...getButtonProps({ sentiment: "default" }).button}
            onClick={handleBackClick}
            aria-label={`Go back to A Cloud Guru (${learnUrl})`}
          >
            Go back to A Cloud Guru
          </button>
          <button
            {...getButtonProps().button}
            onClick={handleForwardClick}
            aria-label={`Continue to Skills (${appUrl})`}
            id='cta'
          >
            Continue to Skills
          </button>
        </div>

        <Checkbox
          label="Don't show me this again"
          checked={dontShow}
          onClick={handleCheckboxClick}
        />
      </div>
    </div>
  );
};

export default App;
